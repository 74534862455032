<template>
  <v-container fluid>
    <Header />
    <v-container page id="contenuto">
      <h2>Note legali e privacy</h2>
      <p>
        ll sito Aria Ambiente di Regione Piemonte intende offrire un servizio di
        informazione sempre accessibile per far conoscere la realtà del
        territorio e l'amministrazione locale.
      </p>
      <h3>Modalità per link</h3>
      <p>
        Il soggetto che intende inserire il link deve darne preventiva
        comunicazione a Regione Piemonte inviando un e-mail all' indirizzo
        <a
          href="mailto:risanamento.atmosferico@regione.piemonte.it"
          rel="nofollow"
          >risanamento.atmosferico@regione.piemonte.it</a
        >
        indicando i propri dati identificativi. Al medesimo indirizzo andranno
        comunicate tutte le variazioni riguardanti la pubblicazione del link
        (eliminazione, modifica o altro).
      </p>
      <p>
        Il link al sito
        <a
          href="https://aria.ambiente.piemonte.it"
          target="_blank"
          rel="nofollow"
          >aria.ambiente.piemonte.it</a
        >
        è autorizzato alle seguenti condizioni:
      </p>
      <div class="lista">
        <ul>
          <li>
            il link non deve determinare nell'utente confusione circa: la
            titolarità del sito, le attività del soggetto che effettua il link e
            quelle offerte dal portale Aria Ambiente della Regione Piemonte.
          </li>
          <li>
            il link deve essere effettuato in modo tale che il collegamento al
            sito avvenga in modo diretto alla prima pagina.
          </li>
        </ul>
      </div>
      <p>
        Regione Piemonte si riserva di revocare la presente autorizzazione in
        qualsiasi momento.<br />
        In ogni caso la realizzazione di un link al sito
        <a href="https://aria.ambiente.piemonte.it" rel="nofollow"
          >aria.ambiente.piemonte.it</a
        >
        costituisce integrale accettazione delle presenti regole.
      </p>
      <h3>Dati relativi alla qualità dell’aria</h3>
      <p>
        I dati messi a disposizione sul presente sito, come consultabili e
        liberamente scaricabili, sono messi a disposizione dal titolare, Regione
        Piemonte, ai sensi della licenza Creative Commons CC BY 4.0. Possono
        essere riutilizzati nel rispetto della suddetta licenza, richiamando il
        titolare del database, Regione Piemonte.
      </p>
      <h3>Copyright sugli altri contenuti del sito</h3>
      <p>
        Tutti gli altri contenuti (testi, immagini, filmati, marchi, loghi,
        banner, testate, audiovisivi, ecc...), i domini, e ogni altro segno
        distintivo che compaiono in questo sito sono di proprietà della Regione
        Piemonte e/o di terzi e sono protetti ai sensi della vigente normativa
        sul diritto d'autore, sui brevetti, e su quelle relative alla proprietà
        intellettuale.
      </p>
      <p>
        Essi possono essere utilizzati previa autorizzazione di Regione
        Piemonte.
      </p>
      <p>I contenuti del sito non hanno valore legale.</p>
      <h3>Utilizzo del sito</h3>
      <p>
        In nessun caso Regione Piemonte potrà essere ritenuta responsabile dei
        danni di qualsiasi natura causati direttamente o indirettamente
        dall'accesso al sito, dall'utilizzo degli strumenti interattivi,
        dall'incapacità o impossibilità di accedervi, dall'utilizzo delle
        notizie in esso contenute.
      </p>
      <p>
        Sono consentite citazioni a titolo di cronaca o recensione se
        accompagnate dall'indicazione della fonte e della url.
      </p>
      <p>
        Regione Piemonte si riserva il diritto di riprodurre i testi in altre
        pubblicazioni e di modificare i contenuti del sito e delle note legali
        in qualsiasi momento e senza alcun preavviso.
      </p>
      <p>
        I dati pubblicati relativi alle misure e ai modelli sono soggetti a
        revisione da parte di ARPA Piemonte e possono venire aggiornati anche
        successivamente al giorno di pubblicazione in seguito alle attività di
        validazione e certificazione degli stessi.
      </p>
      <h3>Segnalazione errori</h3>
      <p>
        Segnalate errori o malfunzionamenti scrivendo a
        <a
          href="mailto:risanamento.atmosferico@regione.piemonte.it"
          rel="nofollow"
          >risanamento.atmosferico@regione.piemonte.it</a
        >. Provvederemo a correggerli quanto prima.
      </p>
      <h3>Accesso a siti esterni collegati</h3>
      <p>
        Regione Piemonte non è in alcun modo responsabile dei contenuti dei siti
        collegati al proprio sito tramite collegamenti ipertestuali: delle
        informazioni ottenute dagli utenti tramite l'accesso ai siti esterni
        sono responsabili i soggetti titolari dei vari siti.
      </p>
      <h3>Download</h3>
      <p>
        Le informazioni riportate in questa pagina si applicano anche
        nell'utilizzo da parte degli utenti dei materiali scaricabili da questo
        sito.
      </p>
      <h2>Privacy Policy</h2>
      <p>
        L’informativa sulle le modalità di trattamento dei dati personali degli
        utenti che consultano il portale di Regione Piemonte viene resa ai sensi
        dell’art. 13 del Regolamento UE 2016/679 ‘Regolamento Generale sulla
        protezione dei dati' (“RGPD”) e della normativa italiana vigente per la
        protezione dei dati personali.<br />
        La Giunta regionale del Piemonte, in qualità di titolare del trattamento
        dei dati personali di coloro che interagiscono con il sito di Regione
        Piemonte a partire dall’indirizzo
        <a href="https://aria.ambiente.piemonte.it" rel="nofollow"
          >aria.ambiente.piemonte.it
        </a>
        (e non per altri siti web esterni ed eventualmente collegati a questo
        tramite link), fornisce le seguenti informazioni e definizioni.<br />
        Per trattamento di dati personali si intende qualsiasi operazione o
        insieme di operazioni, compiute con o senza l'ausilio di processi
        automatizzati e applicate a dati personali o insiemi di dati personali,
        anche se non registrati in una banca di dati, come la raccolta, la
        registrazione, l'organizzazione, la strutturazione, la conservazione,
        l'elaborazione, la selezione, il blocco, l'adattamento o la modifica,
        l'estrazione, la consultazione, l'uso, la comunicazione mediante
        trasmissione, la diffusione o qualsiasi altra forma di messa a
        disposizione, il raffronto o l'interconnessione, la limitazione, la
        cancellazione o la distruzione.
      </p>
      <h3>Finalità del trattamento</h3>
      <p>
        A seguito della consultazione di questo sito possono essere trattati
        dati relativi a persone identificate o identificabili. I dati forniti
        liberamente e volontariamente dagli utenti vengono acquisiti e trattati
        nel rispetto delle regole fissate dal Regolamento per le seguenti
        finalità:
      </p>
      <div class="lista">
        <ul>
          <li>erogazione ed esecuzione dei servizi&nbsp; di newsletter;</li>
          <li>
            esecuzione dei propri compiti di interesse pubblico o comunque
            connessi all'esercizio dei propri pubblici poteri, ivi incluse le
            finalità di archiviazione, di ricerca storica e di analisi per scopi
            statistici;
          </li>
          <li>
            adempimenti di obblighi previsti da norme di legge, regolamenti,
            normativa comunitaria.
          </li>
        </ul>
      </div>
      <p>
        Le newsletter della Regione Piemonte sono disponibili, gratuitamente, a
        chi ne fa espressa richiesta, iscrivendosi liberamente. La revoca
        dell'iscrizione è possibile seguendo il collegamento posto in calce ad
        ogni comunicazione della newsletter. Per la revoca dei servizi specifici
        è possibile inviare un’email al responsabile del servizio stesso.
      </p>
      <div class="lista">
        <ul>
          <li>
            gli indirizzi IP (nomi di dominio dei computer utilizzati per
            connettersi al sito);
          </li>
          <li>
            gli indirizzi in notazione URI (Uniform Resource Identifier) delle
            risorse richieste;
          </li>
          <li>l'orario della richiesta;</li>
          <li>il metodo utilizzato nel sottoporre la richiesta al server;</li>
          <li>la dimensione del file ottenuto in risposta;</li>
          <li>
            il codice numerico indicante lo stato della risposta data dal
            server;
          </li>
          <li>
            altri parametri relativi al sistema operativo e all'ambiente
            informatico dell'utente.
          </li>
        </ul>
      </div>
      <p>
        Questi dati vengono mantenuti per il periodo minimo richiesto dalla
        normativa vigente. I dati potrebbero essere utilizzati per
        l’accertamento di responsabilità in caso di ipotetici reati informatici
        ai danni del sito.
      </p>
      <h3>Diritti degli interessati</h3>
      <p>
        La Regione non utilizza modalità di trattamento basate su processi
        decisionali automatici (art. 22). Gli interessati possono avvalersi, ove
        applicabili, dei seguenti diritti previsti dalla normativa vigente:
      </p>
      <div class="lista">
        <ul>
          <li>diritti di accesso (art. 15)</li>
          <li>di rettifica (art. 16)</li>
          <li>di cancellazione (art. 17)</li>
          <li>di limitazione (art. 18)</li>
          <li>di notifica (art. 19)</li>
          <li>di portabilità (art. 20)</li>
          <li>di opposizione (art. 21)</li>
        </ul>
      </div>
      <p>
        Ciascun interessato potrà esercitare i propri diritti&nbsp;mediante la
        compilazione&nbsp;del seguente modulo, disponibile in due formati:
      </p>
      <div class="lista">
        <ul>
          <li>
            <a
              href="https://www.regione.piemonte.it/web/sites/default/files/media/documenti/2019-12/MODELLO%20Esercizio%20Diritti%20Interessato%20GDPR%202016-679.pdf"
              rel="nofollow"
              >Modello per l'esercizio dei diritti dell'interessato - formato
              .pdf</a
            >
          </li>
          <li>
            <a
              href="https://www.regione.piemonte.it/web/sites/default/files/media/documenti/2019-12/MODELLO%20Esercizio%20Diritti%20Interessato%20GDPR%202016-679.docx"
              rel="nofollow"
              >Modello per l'esercizio dei diritti dell'interessato - formato
              .docx</a
            >
          </li>
        </ul>
      </div>
      <p>
        Il Titolare del trattamento dati è la Giunta regionale, piazza Castello
        165, 10121 Torino, tel. 011-432.11.11; I dati di contatto del
        Responsabile della protezione dati (DPO) sono
        <a href="mailto:dpo@cert.regione.piemonte.it" rel="nofollow"
          >dpo@cert.regione.piemonte.it</a
        >
        e
        <a href="mailto:dpo@regione.piemonte.it" rel="nofollow"
          >dpo@regione.piemonte.it</a
        >.
      </p>
      <p>
        Oltre alle tutele previste in sede amministrativa o giurisdizionale, è
        ammesso comunque il reclamo all'Autorità Garante nel caso si ritenga che
        il trattamento avvenga in violazione del Regolamento citato seguendo le
        procedure e le indicazioni pubblicate sul sito web ufficiale
        dell’Autorità su
        <a href="http://www.garanteprivacy.it/" rel="nofollow"
          >www.garanteprivacy.it</a
        >.
      </p>
      <h3>Utilizzo dei cookies</h3>
      <p>
        <v-btn outlined :to="{ name: 'cookie' }"
          >Visualizza l'informativa</v-btn
        >
      </p>
    </v-container>
  </v-container>
</template>

<script>
import Header from "@/components/layout/Header";

export default {
  name: "Home",
  components: { Header }
};
</script>
